import { format as formatNumber } from '../number';
import { CurrencyFormatId } from './currency_format_id';
import currentFormatId from './current_format_id';
import { isFraction, toDecimal, toThreeDecimals } from './helpers';

const formatters = {
  [CurrencyFormatId.US]: (decimal) => (
    decimal >= 1 ? `+${Math.round(decimal * 100)}` : `-${Math.round((1 / decimal) * 100)}`
  ),
  [CurrencyFormatId.DECIMAL]: (decimal) => formatNumber(toThreeDecimals(1 + decimal)),
  [CurrencyFormatId.INDONESIAN]: (decimal) => (
    decimal >= 1 ? toThreeDecimals(decimal) : `-${toThreeDecimals(1 / decimal)}`
  ),
  [CurrencyFormatId.CHINESE]: toThreeDecimals,
};

export default (price) => {
  if (!price) return price;

  // I have kept this check
  // I imagine in certain cases this function is wrongly called with not a fraction
  // It would be ideal to keep and respect a contract
  if (isFraction(price)) {
    const formatter = formatters[currentFormatId()];

    return formatter ? formatter(toDecimal(price)).toString() : price;
  }

  return price.toString();
};
